.layout-container {
  margin: 0 auto;
  max-width: 1300px;
}

.ap-green {
  background-color: #009a49 !important;
}

.ap-top-bar {
  padding: 8px 0;
  color: #ffffff;
  height: 40px;
}

.ap-top-bar a,
.ap-top-bar a:hover {
  color: #ffffff;
  text-decoration: none;
}

input[type="button"],
button {
  color: #009a49;
  border: solid 1px #009a49 !important;
}

.line-style-1 {
  box-sizing: border-box;
  height: 1px;
  border-bottom: 2px solid #009a49;
}

.react-datepicker-wrapper {
  display: unset !important;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker__input-container input {
  width: 100%;
}

.btn-link {
  border: none !important;
}
